<template>
  <div class="container">
    <SimpleHeader />
    <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
      <div class="picye">
        <p class="picye_title">
          <span>{{ balance.name }}</span>
          <van-popover theme="dark" class="popover" v-model:show="balance.showTip">
            <div class="tips">{{balance.tips}}</div>
            <template #reference>
              <van-icon class="wen" name="question-o" @click.stop="toggleTips(1)" />
            </template>
          </van-popover>
        </p>
        <p class="picye_money">
          <span>{{ balanceInfo.balance }}</span>
        </p>
        <div class="picye_btn">
          <div @click="toPage('/walletDetail')">
            <van-icon class="edit-icon" name="orders-o" />
            <span>明细</span>
          </div>
        </div>
      </div>
      <van-cell-group title="" class="card-box">
        <van-cell center  is-link v-for="(item, index) in toolList" :key="index" @click="toPage(item.url)">
          <template #title>
            <span class="custom-title">{{ item.name }}</span>
            <van-popover theme="dark" class="popover" v-model:show="item.showTip">
              <div class="tips">{{item.tips}}</div>
              <template #reference>
                <van-icon class="wen" name="question-o" @click.stop="toggleTips(2,index)" />
              </template>
            </van-popover>
          </template>
          <div>¥ {{balanceInfo[item.key]}}</div>
        </van-cell>
      </van-cell-group>
    </van-pull-refresh>
  </div>
</template>
<script>
import SimpleHeader from '@/components/SimpleHeader'
import { onMounted, nextTick, inject,reactive,toRefs } from 'vue'
import { useRouter } from 'vue-router'
import { Toast, PullRefresh } from 'vant'
import { getWalletView,getWalletAmount } from '@/api/wallet'
import usePage from '@/hooks/usePage'
import to from 'await-to-js';
export default {
  name: 'wallet',
  components: {
    [PullRefresh.name]: PullRefresh,
    SimpleHeader
  },
  setup() {
    const _appLoadFanish = inject('appLoadFanish');
    const { navigateTo } = usePage()
    const state = reactive({
      balance:{},
      toolList:[],
      refreshing: false,
      loading: false,
      balanceInfo:{}
    })
    const toPage = url => {
      navigateTo(url)
    }
    const tips = () => {
      Toast('敬请期待')
    }
    const initData = () => {
      state.balance = {}
      state.toolList = []
    }
    const getData = async ()=> {
      if (state.refreshing) {
        initData()
        state.refreshing = false
      }
      let [error,result] = await to(getWalletView())
      _appLoadFanish()
      state.loading = false
      if(error) return;
      let {code, res} = result;
      if (code === 0) {
        state.balance = res.balance
        state.toolList = res.toolList
      }
    }
    //刷新页面
    const onRefresh = () => {
      state.loading = true
      getData()
    }

    function toggleTips(type = 1,index) {
      if (type === 1) {
        state.balance.showTip = !state.balance.showTip
      } else {
        state.toolList[index].showTip = !state.toolList[index].showTip
      }
    }

    const getAmount = async ()=>{
      let [error,result] = await to(getWalletAmount())
      if(error) return;
      let {code, res} = result;
      if (code == 0) {
        state.balanceInfo = res
      }
    }

    onMounted(async () => {
      getData()
      getAmount()
    })

    return {
      ...toRefs(state),
      toPage,
      onRefresh,
      toggleTips
    }
  }
}
</script>

<style lang="less" scoped>
@import '../../common/style/searchpicker';
.picye {
 .biswh('https://x.jzbull.com/dsx-h5/wallet-bg.png', 345px, 188px);
  margin: 12px auto;
  padding-top: 30px;
  position: relative;
  &_title {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    text-align: center;
    color: #ffffff;
    line-height: 22px;
    .sc(14px,#000,400);
    position: relative;
  }
  &_money {
    font-size: 36px;
    font-weight: normal;
    text-align: center;
    color: #ffffff;
    line-height: 36px;
    margin-top: 12px;
    > span:first-of-type{
      .sc(36px,#000,400)
    }
  }
  &_btn {
    display: flex;
    position: absolute;
    width: 100%;
    height: 75px;
    bottom: 0;
    > div {
      flex: 1;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
      .sc(14px,#000,400);
      .edit-icon{
        margin-right: 5px;
      }
    }
  }
}
.card-box {
  background: #ffffff;
  border-radius: 10px;
  overflow: hidden;
  margin-bottom: 10px;
  overflow: hidden;
  margin: 15px ;
}
.cell-it{
  width: 100%;
  position: absolute;
  z-index: 2000;
  display: flex;
  align-items: center;
  >span{
    margin-right: 5px;
  }
}

.popover{
  z-index: 2021;
}
.tips{
  padding: 5px 10px;
}
.wen{
  margin-left: 5px;
}
.van-cell{
  padding: 13px 16px;
}
</style>
