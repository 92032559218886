import {post,get} from '../utils/axios'

// 钱包视图
export function getWalletView(params) {
  return post('wallet/getView', params)
}
// 钱包明细
export function getWalletDetail(params) {
  return post('wallet/getDetail', params)
}
// 钱包金额
export function getWalletAmount(params) {
  return post('wallet/getData', params)
}
// 我的页面钱包金额
export function getWallet(params) {
  return post('wallet/getWallet', params)
}

// 钱包下拉选项
export function getWalletOptions(params) {
  return post('wallet/getOptions', params)
}

export function getWalletStatistics(params) {
  return post('wallet/getView', params)
}
export function getWalletList(params) {
  return post('wallet/getView', params)
}

export function getWalletInfo(params) {
  return post('wallet/getView', params)
}